import {createRouter, createWebHistory, Router} from 'vue-router'
import LandingPage from '@/views/LandingPage/LandingPage.vue'
import Terms from "@/views/LandingPage/Terms.vue";
import Privacy from "@/views/LandingPage/Privacy.vue";
import Dashboard from "@/views/Dashboard/Dashboard.vue";
import GoogleLogin from "@/views/Login/GoogleLogin.vue";
import Login from "@/views/Login/Login.vue";
import Clips from "@/views/Clips/Clips.vue";
import Clip from "@/views/Preview/ClipView.vue";
import Podcast from "@/views/Marketing/Podcast.vue";
import EditorServices from "@/views/Marketing/EditorServices.vue";
import Zoom from "@/views/Marketing/Zoom.vue";
import Blog from "@/views/Marketing/Blog.vue";

const router:Router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: LandingPage
    },

    {
      path: '/terms',
      name: 'terms',
      component: Terms
    },


    {
      path: '/privacy',
      name: 'privacy',
      component: Privacy
    },

    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard
    },

    {
      path: '/login',
      name: 'login',
      component: Login
    },

    {
      path: '/zero-to-community',
      name: 'zero-to-community',
      component: Podcast
    },

    {
      path: '/zoom',
      name: 'zoom',
      component: Zoom,
    },


    {
      path: '/sam-edits-podcasts',
      name: 'sam-edits-podcasts',
      component: EditorServices
    },
    {
      path: '/blog',
      name: 'blog',
      component: Blog
    },

    {
      path: '/google_login/:token/:photo',
      name: 'google',
      component: GoogleLogin
    },

    {
      path: '/clips/:id/view/:clip_id',
      name: 'view',
      component: Clip
    },



    {
      path: '/clips/:id',
      name: 'clips',
      component: Clips
    },


  ]
})

export default router
