<script setup lang="ts">


import { ref, onMounted } from 'vue';
import { trackEvent} from '@/libs/utils.ts'

const items = ref([
  { type: 'video', src: 'https://s3.us-east-2.amazonaws.com/katana.video/images/fiverr-trailer.mp4', poster: 'https://katana.video/images/zero-to-community-1.png', alt: 'Zero to Community' },

  { type: 'video', src: 'https://s3.us-east-2.amazonaws.com/katana.video/images/lauren-sam-zero-to-community.mp4', poster: 'https://katana.video/images/zero-to-community-1.png', alt: 'Zero to Community' },
  { type: 'video', src: 'https://s3.us-east-2.amazonaws.com/katana.video/images/reimbi.mp4', poster: 'https://katana.video/images/zero-to-community-1.png', alt: 'How We interview' },
  { type: 'video', src: 'https://katana.video/images/ghana-we-day-take-3.mp4', alt: 'Image 2' },
]);

const currentIndex = ref(0);

const next = () => {
  currentIndex.value = (currentIndex.value + 1) % items.value.length;

  const videos = document.querySelectorAll('video');
  videos.forEach(video => {
    video.pause();
  });
};

const prev = () => {
  currentIndex.value = (currentIndex.value - 1 + items.value.length) % items.value.length;
  const videos = document.querySelectorAll('video');
  videos.forEach(video => {
    video.pause();
  });
};

function trackPlay(){
  trackEvent('play-portfolio')
}

onMounted(() => {
  // Preload images and videos
  items.value.forEach(item => {
    if (item.type === 'image') {
      const img = new Image();
      img.src = item.src;
    } else if (item.type === 'video') {
      const video = document.createElement('video');
      video.src = item.src;
      video.poster = item.poster!;
    }
  });

  trackEvent('view-portfolio')
});
const goToSlide = (index) => {
  currentIndex.value = index;

  const videos = document.querySelectorAll('video');
  videos.forEach(video => {
    video.pause();
  });
};

function openCalendar(){
  trackEvent('open-calendar');
}

</script>

<template class="justify-between flex flex-col">

  <div>  </div>



  <section class="bg-white dark:bg-gray-900">



    <div class="grid max-w-screen-lg px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">

      <div class="hidden lg:mt-0 lg:col-span-5 lg:flex ">
        <img src="@/assets/profile_headshot.jpg" class="rounded-3xl h-80" alt="mockup">
      </div>
      <div class="mr-auto place-self-center lg:col-span-7">
        <h1 class="max-w-2xl mb-4 text-5xl font-extrabold tracking-tight leading-none  dark:text-white"> 👋 Hi, I'm Sam</h1>

        <div class="w-full h-4"></div>
        <p class="max-w-2xl mb-6 font-light text-gray-500 text-xl dark:text-gray-400">I run a 🎙️ podcast called <a href="../zero-to-community" class="underline"> Zero to Community</a>, and last worked at 🦆 Streamyard (tool for remote recording).</p>

        <p class="max-w-2xl mb-6 font-light text-gray-500 text-xl dark:text-gray-400">I also <a class="underline" href="https://www.fiverr.com/sam_katana/edit-podcasts-and-create-shorts" target="_blank"> edit podcasts</a>, and just happy to help anyone getting started with video podcasting</p>

        <a href="https://calendly.com/sambhattacharyya/30-minute-meeting" target="_blank" @click="openCalendar">

          <button  class="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-lg  font-medium text-gray-900 rounded-full group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
<span class="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-full group-hover:bg-opacity-0">
Schedule a call
</span>
          </button>
        </a>

      </div>

    </div>
  </section>

  <div class="w-full text-center text-2xl mb-8 text-stone-600 font-light"> Some podcasts I edited for friends 👇</div>


  <div class="flex w-full items-center justify-center relative">


    <div class="flex justify-center w-full relative">


      <div class="relative w-full max-w-[854px]">

        <div class="absolute top-4 mt-1 left-4 right-0 z-50 flex  space-x-2">
          <button
              v-for="(_, index) in items"

              @click="goToSlide(index)"
              class="w-3 h-3 rounded-full transition-all duration-300 focus:outline-none"
              :class="index === currentIndex ? 'bg-indigo-600' : 'bg-indigo-200'"
          ></button>
        </div>

        <div class="overflow-hidden">
          <div
              class="flex transition-transform duration-300 ease-in-out"
              :style="{ transform: `translateX(-${currentIndex * 100}%)` }"
          >
            <div
                v-for="(item, index) in items"
                :key="index"
                class="w-full flex-shrink-0"
            >
              <img
                  v-if="item.type === 'image'"
                  :src="item.src"
                  :alt="item.alt || ''"
                  class="w-full h-[480px] object-cover"
              />
              <video
                  @click="trackPlay"
                  v-else-if="item.type === 'video'"
                  :src="item.src"
                  controls
                  class="w-full h-[480px] object-cover"
              ></video>
            </div>
          </div>
        </div>
        <button
            @click="prev"
            class="absolute top-1/2 left-4 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
        >
     <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg class="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4"/>
            </svg>
            <span class="sr-only">Previous</span>
        </span>
        </button>
        <button
            @click="next"
            class="absolute top-1/2 right-4 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full"
        >
        <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg class="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
            </svg>
            <span class="sr-only">Next</span>
        </span>
        </button>


      </div>


    </div>


  </div>

  <div>  </div>

</template>

<style scoped>

</style>